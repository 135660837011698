<template>
  <div class="top-sales-wrapper">
    <div class="chart-wrapper">
      <div class="best-seller-list">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ $t('Service ID') }}</th>
              <th scope="col">{{ $t('Service Name') }}</th>
              <th scope="col">{{ $t('Service Price') }}</th>
              <th scope="col">{{ $t('Sales') }}</th>
              <th scope="col">{{ $t('Actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(service, index) in bestSellers">
              <td>{{ service.reference_id }}</td>
              <td v-if="langcode === 'en'">{{ service.service_name_en }}</td>
              <td v-else="langcode === 'zh'">{{ service.service_name_zh }}</td>
              <td>HK${{ service.service_price }}</td>
              <td>{{ service.related_orders.length }}</td>
              <td><a href="#">{{ $t('Check Record') }}</a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
dayjs.extend(localeData);
import 'dayjs/locale/zh-hk';

import BarChart from '@/components/chart/BarChart.vue';
import Common from '@/lib/common';
import Report from '@/lib/report';

export default {
  name: 'ServiceReportTable',
  components: {
    BarChart,
  },
  async mounted(){
    let bestSellers = await this.loadBestSellers();
    this.bestSellers = bestSellers;

    const serviceNames = [];
    const ordersData = [];

    for(let i = 0; i < bestSellers.length; i++){
      if(this.langcode === 'zh'){
        const serviceName = bestSellers[i].service_name_zh;
        serviceNames.push(serviceName);
      }else{
        const serviceName = bestSellers[i].service_name_en;
        serviceNames.push(serviceName);
      }
      const orderNum = bestSellers[i].related_orders.length;
      ordersData.push(orderNum);
    }

    const chartData = {
      labels: serviceNames,
      datasets: [
        {
          label: this.$t('Top Seller'),
          backgroundColor: '#343A40',
          data: ordersData,
        }
      ],
    };
    this.chartData = chartData;
  },
  methods:{
    async loadBestSellers(){
      try{
        const loginInfo = Common.getLoginInfo();
        const loadBestSellers = await Report.loadBestSellers(this.apiUrl, loginInfo);
        return loadBestSellers;
      }catch(err){
        console.log(err);
      }
    },
  },
  data(){
    return {
      bestSellers: [],
      chartData: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      }
    }
  },
  watch: {
    'langcode': function(){
      //Watch the langcode change, and then update the chart data.
      const serviceNames = [];
      for(let i = 0; i < this.bestSellers.length; i++){
        if(this.langcode === 'zh'){
          const serviceName = this.bestSellers[i].service_name_zh;
          serviceNames.push(serviceName);
        }else{
          const serviceName = this.bestSellers[i].service_name_en;
          serviceNames.push(serviceName);
        }
      }
      this.chartData.labels = serviceNames;
      this.chartData.datasets[0].label = this.$t('Top Seller');
      const newCharData = Object.assign({}, this.chartData);
      this.chartData = newCharData;
    }
  },
  computed: mapState({
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>
