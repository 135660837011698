<template>
  <div class="daily-report">
    <div class="page-header">
      <h1>{{ $t('Report') }} > {{$t('Service Report') }} </h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('單一服務報告') }}</h2>
      </div>
      <ServiceReportTable />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ServiceReportTable from '@/components/report/ServiceReportTable.vue';

export default {
  name: 'ServiceReport',
  components: {
    ServiceReportTable,
  },
  data(){
    return {
      activeName: 'Total Sales',
    };
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.daily-report{
  .page-content .content-tabs .el-tabs .el-tabs__content{
    display: block;
    padding-left: 25px;
  }
  .top-sales-wrapper{
    margin-top: 50px;
    width: 100%;
    padding-left: 20px;
  }
  .best-seller-list{
    padding-left: 20px;
    margin-top: 30px;
  }
}
</style>
